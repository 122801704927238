import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEOComponent from "../../components/seo"
import { Hero } from "../../components/hero"

export const query = graphql`
  query LoginPageQuery {
    file(name: { eq: "log-in-header" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const LoginPage = ({data}) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return(
    <Layout>
      <SEOComponent title="Login" />
      <Hero image={banner} header="Login" />
      <section className="container my-4 mx-auto pt-0 sm:px-24 px-4 pb-6">
        <h2 className="text-3xl">Log in to Strong Foundations here.</h2>
        <p className="text-lg">
          If you have not yet created an account, please{" "}
          <Link to="/account/sign-up/" title="Sign up">
            sign up here
          </Link>{" "}
          to receive our email newsletter and access your Account.
        </p>
        <form>
          <div className="mx-auto my-4 w-1/2">
            <input
              type="email"
              placeholder="email address"
              className="p-3 placeholder-purple-100 text-purple-500 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            />
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default LoginPage
